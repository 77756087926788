@font-face {
  font-family: 'PPNeueMontreal';
  src: url('./assets/fonts/PPNeueMontreal-Medium.woff2');
  src: url('./assets/fonts/PPNeueMontreal-Medium.woff2') format('woff2'),
    url('./assets/fonts/PPNeueMontreal-Medium.woff') format('woff'),
    url('./assets/fonts/PPNeueMontreal-Medium.ttf') format('truetype'),
    url('./assets/fonts/PPNeueMontreal-Medium.otf') format('otf');
  font-weight: 500;
}

@font-face {
  font-family: 'PPNeueMontreal';
  src: url('./assets/fonts/PPNeueMontreal-Regular.woff2');
  src: url('./assets/fonts/PPNeueMontreal-Regular.woff2') format('woff2'),
    url('./assets/fonts/PPNeueMontreal-Regular.woff') format('woff'),
    url('./assets/fonts/PPNeueMontreal-Regular.ttf') format('truetype'),
    url('./assets/fonts/PPNeueMontreal-Regular.otf') format('otf');
  font-weight: 400;
}

* {
  box-sizing: border-box;
  font-family: 'PPNeueMontreal', sans-serif !important;
  font-weight: 500;
}

.grecaptcha-badge {
  right: -1860px !important;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: -0.2px;
}
